<template>
  <div class="content_body Product" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small" :gutter="24" @keyup.enter.native="getSearchData">
            <el-form-item label="产品名称">
              <el-input v-model="Name" @clear="getSearchData" clearable placeholder="输入产品名称/别名/条码"></el-input>
            </el-form-item>
            <el-form-item label="产品分类">
              <el-cascader @change="getSearchData" @clear="getSearchData" :options="productTypeList" :show-all-levels="true" clearable filterable :props="cascaderProps" v-model="typeValue" placeholder="请选择产品分类"></el-cascader>
            </el-form-item>
            <el-form-item label="产品品牌">
              <el-select v-model="ProductBrandID " clearable filterable placeholder="请选择产品品牌" :default-first-option="true" @change="getSearchData" @clear="getSearchData">
                <el-option v-for="item in productBrandList" :key="item.ID" :label="item.Name" :value="item.ID">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否上架">
              <el-select @clear="getSearchData" @change="getSearchData" v-model="IsAllowSell" placeholder="请选择是否上架" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getSearchData" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" ref="multipleTable" :data="productList">
        <el-table-column prop="Name" label="产品名称"></el-table-column>
        <el-table-column prop="Alias" label="产品别名"></el-table-column>
        <el-table-column prop="PCategoryName" label="产品分类"></el-table-column>
        <el-table-column prop="ProductBrandName" label="产品品牌"></el-table-column>
        <el-table-column prop="BarCode" label="产品条码"></el-table-column>
        <el-table-column prop="UnitName" label="包装单位"></el-table-column>
        <el-table-column prop="Price" label="销售价格">
          <template slot-scope="scope">￥{{scope.row.Price | NumFormat}}</template>
        </el-table-column>
        <el-table-column label="是否可赠送">
          <template slot-scope="scope">{{scope.row.IsAllowLargess?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="是否上架">
          <template slot-scope="scope">{{scope.row.IsAllowSell?'是':'否'}}</template>
        </el-table-column>
        <el-table-column label="操作" width="145">
          <template slot-scope="scope">
            <el-button v-if="isProductEdit" type="primary" size="small" @click="showEditDialog(scope.row,'edit')" v-prevent-click>编辑</el-button>
            <el-button type="primary" size="small" @click="showEditDialog(scope.row,'show')" v-prevent-click>详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 dis_flex flex_x_between" v-if="false">
        <div>
          <el-dropdown trigger="click" @command="dropdownClick" size="small">
            <el-button type="primary" size="small" v-prevent-click>
              批量设置
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">是否上架</el-dropdown-item>
              <el-dropdown-item command="2">是否可以赠送</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>
    <!--增加、编辑,弹出框-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="950px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <div class="pad_15">
            <el-form :model="ruleForm_add" :rules="rules_add" ref="ruleForm_add" label-width="120px" size="small">
              <el-col :span="12">
                <el-form-item label="产品名称">
                  <el-input v-model="ruleForm_add.Name" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品别名">
                  <el-input v-model="ruleForm_add.Alias" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品分类">
                  <el-input v-model="ruleForm_add.PCategoryName" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品条码">
                  <el-input v-model="ruleForm_add.BarCode" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="包装单位">
                  <el-input v-model="ruleForm_add.UnitName" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品规格">
                  <el-input v-model="ruleForm_add.Specification" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格" prop="Price">
                  <el-input v-model="ruleForm_add.Price" v-enter-number2 type="number">
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="产品品牌">
                  <el-input v-model="ruleForm_add.ProductBrandName" :disabled="true"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="是否上架" required>
                  <el-radio-group v-model="ruleForm_add.IsAllowSell">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可以赠送" prop="IsAllowLargess">
                  <el-radio-group v-model="ruleForm_add.IsAllowLargess">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="second">
          <div class="dis_flex flex_dir_row">
            <goodsDetail ref="goodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" GoodsType="产品" @OriginalText_Change="OriginalText_Change" @Memo_change="Memo_change" @ImageListChange="ImageListChange">
              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24">产品规格: {{ruleForm_add.Specification}}</el-col>
                  <el-col :span="24">包装单位: {{ruleForm_add.UnitName}}</el-col>
                </el-col>
              </el-row>
            </goodsDetail>
          </div>

        </el-tab-pane>
        <el-tab-pane label="销售范围" name="third">
          <span slot="label">
            销售范围
            <el-popover placement="top-start" width="200" trigger="hover">
              <p>适用于同级所有节点，则只需勾选父节点。</p>
              <p>比如：适用于所有节点，只需勾选“顶级/第一个”节点。</p>
              <el-button type="text" style="color:#dcdfe6" icon="el-icon-info" slot="reference"></el-button>
            </el-popover>
          </span>
          <el-scrollbar class="el-scrollbar_height">
            <el-tree ref="treeSale" :expand-on-click-node="false" :check-on-click-node="true" :check-strictly="true" :data="salesScope" show-checkbox node-key="ID" :default-checked-keys="defaultCheckedKeysSale" :default-expanded-keys="defaultExpandedKeysSale" :props="defaultProps"></el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm_add')" size="small" v-prevent-click :loading="updateLoading">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 查看弹出框 -->
    <el-dialog :title="dialogTitle" :visible.sync="showDialogVisible" width="950px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="first">
          <div class="pad_15">
            <el-form class="productShow" label-width="150px" size="small">
              <el-col :span="12">
                <el-form-item label="产品名称：">
                  <span>{{ruleForm_add.Name}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品别名：">
                  <span>{{ruleForm_add.Alias}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品分类：">
                  <span>{{ruleForm_add.PCategoryName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品条码：">
                  <span>{{ruleForm_add.BarCode}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="包装单位：">
                  <span>{{ruleForm_add.UnitName}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产品规格：">
                  <span>{{ruleForm_add.Specification}}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="销售价格：">
                  <span>{{ruleForm_add.Price}}元</span>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="产品品牌：">
                  <span>{{ruleForm_add.ProductBrandName}}</span>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="是否上架：">
                  <span v-if="ruleForm_add.IsAllowSell">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否可以赠送：">
                  <span v-if="ruleForm_add.IsAllowLargess">是</span>
                  <span v-else>否</span>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="second">
          <div class="dis_flex flex_x_center">
            <showGoodsDetail ref="ShowGoodsDetail" :Memo="ruleForm_add.Memo" :ImageList="ruleForm_add.ImageList" :OriginalText="ruleForm_add.OriginalText" :Price="ruleForm_add.Price" :Name="ruleForm_add.Name" GoodsType="产品">
              <el-row slot="info">
                <el-col :span="24" class="padlt_10 padrt_10" style="height:40px;line-height:40px">商品信息</el-col>
                <el-col :span="24" class="back_f8 pad_10">
                  <el-col :span="24">产品规格: {{ruleForm_add.Specification}}</el-col>
                  <el-col :span="24">包装单位: {{ruleForm_add.UnitName}}</el-col>
                </el-col>
              </el-row>
            </showGoodsDetail>
          </div>

        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!--批量设置弹出框-->
    <el-dialog title="批量设置" :visible.sync="setBatchsDialogVisible" width="30%">
      <el-form :model="setBatchForm" :rules="setBatchRules" ref="setBatchForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="是否上架" prop="isUpperShelf" v-if="selectType == '1'">
          <el-radio-group v-model="setBatchForm.isUpperShelf">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否可以赠送" prop="isGift" v-if="selectType == '2'">
          <el-radio-group v-model="setBatchForm.isGift">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('setBatchForm')" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" @click="submitSetBatch('setBatchForm')" v-prevent-click>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/iBeauty/Goods/productSale";
import APIC from "@/api/PSI/Product/productCategory";
import productBrandAPI from "@/api/PSI/Product/productBrand";
import permission from "@/components/js/permission.js";
import utils from "@/components/js/utils.js";
import goodsDetail from "../Goods/Components/GoodsDetail.vue";
import showGoodsDetail from "../Goods/Components/ShowGoodsDetail.vue";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
import Enumerable from "linq";

export default {
  name: "Product",
  /**  引入的组件  */
  components: {
    goodsDetail,
    showGoodsDetail,
  },
  data() {
    return {
      dialogVisible: false, // 增加、编辑弹出框状态
      showDialogVisible: false, //查看弹出层
      setDetailsDialogVisible: false, // 设置明细弹出框状态
      setBatchsDialogVisible: false, // 批量设置弹出框状态
      IsAllowSell: "", // 是否上架状态
      loading: false, // 加载状态
      updateLoading: false,
      activeName: "first", // 当前所选中的标签页
      Name: "", // 搜索框数据
      dialogTitle: "", // 增加、编辑弹出框标题,
      selectType: "", // 判断批量设置类型
      typeValue: "", // 所选中的分类
      productID: "", // 当前所编辑信息的ID
      ProductBrandID: "", // 品牌ID
      productList: [], // 产品列表
      productTypeList: [], // 产品分类列表
      entityList: [],
      salesScope: [], // 销售范围数据
      entityIDList: [], // 所选中的组织单位
      defaultCheckedKeysSale: [],
      defaultExpandedKeysSale: [1],
      defaultProps: {
        children: "Child",
        label: "EntityName",
      }, // 销售范围选择配置项
      setBatchForm: {
        isUpperShelf: "", // 是否上架
        isGift: "", // 是否可以赠送
      }, // 批量设置表单信息
      setBatchRules: {
        isUpperShelf: [
          { required: true, message: "请选择是否上架", trigger: "change" },
        ],
        isGift: [
          { required: true, message: "请选择是否可以赠送", trigger: "change" },
        ],
      }, // 批量设置表单验证
      ruleForm_add: {
        Name: "",
        aliasData: "",
        IsAllowLargess: "",
        BarCode: "",
        PCategoryName: "",
        Specification: "",
        UnitName: "",
        Price: "",
        IsAllowSell: "",
        Memo: "", // 详情信息
        OriginalText: "", //划线价格
        ImageUrlList: [], // 主图列表
      }, // 编辑、新增表单 信息
      rules_add: {
        Price: [
          { required: true, message: "请输入销售价格", trigger: "change" },
        ],
        IsAllowSell: [
          { required: true, message: "请选择是否可以上架", trigger: "change" },
        ],
        IsAllowLargess: [
          { required: true, message: "请选择是否可以赠送", trigger: "change" },
        ],
      }, // 编辑、新增表单验证
      paginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息

      cascaderProps: {
        value: "ID",
        label: "Name",
        children: "Child",
        emitPath: false,
        expandTrigger: "hover",
      }, // 级联选择器配置项
      productBrandList: [],
      isProductEdit: "", //编辑权限
      isProductShow: "", //查看权限
      whichDialog: "",
      editorOption: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isProductEdit = permission.permission(
        to.meta.Permission,
        "iBeauty-Goods-Product-Update"
      );
      vm.getProductBrandList();
    });
  },
  methods: {
    disabledFn() {
      const that = this;
      if (that.whichDialog == "show") {
        return true;
      } else {
        return false;
      }
    },
    // 产品搜索事件
    getSearchData() {
      var that = this;
      that.paginations.page = 1;
      that.getProductList();
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getProductList();
    },
    // 获取产品列表
    getProductList: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Name: that.Name,
        PCategoryID: that.typeValue,
        IsAllowSell: that.IsAllowSell,
        ProductBrandID: that.ProductBrandID,
      };
      API.getProductSaleList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取产品分类
    getProductType: function () {
      var that = this;
      that.loading = true;
      var params = {
        Name: "",
        Active: true,
      };
      APIC.getValidProductCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取产品销售范围
    getProductSaleEntity: function () {
      var that = this;
      that.loading = true;
      var params = {
        ID: that.productID,
      };
      API.getProductSaleEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.defaultCheckedKeysSale = res.Data;
            that.defaultExpandedKeysSale = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取组织单位
    getEntityList: function () {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: "",
      };
      API.getEntityList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 编辑,查看按钮点击事件
    showEditDialog: function (row, type) {
      var that = this;
      that.ruleForm_add = Object.assign({}, row);

      that.productID = row.ID;
      that.activeName = "first";

      that.getProductType();
      that.getEntityList();
      that.dialogVisible = type == "edit" ? true : false;
      that.showDialogVisible = type == "show" ? true : false;
      that.dialogTitle = type == "edit" ? "编辑产品" : "产品详情";
      that.salesScope = [];
      Object.assign(that.salesScope, that.entityList);
      that.defaultCheckedKeysSale = [];
      that.defaultExpandedKeysSale = [1];
      that.getProductSaleEntity();
      that.getProductDetails(that.ruleForm_add.ID);
    },
    // 表单确认事件
    submitForm(ruleForm_add) {
      var that = this;
      that.entityIDList = that.$refs.treeSale.getCheckedKeys();
      that.$refs[ruleForm_add].validate((valid) => {
        if (valid) {
          that.updateProduct();
        } else {
          that.$message.error("请完善基本信息");
          return false;
        }
      });
    },
    // 更新产品信息
    updateProduct: function () {
      var that = this;
      that.updateLoading = true;
      that.loading = true;
      var params = {
        ID: that.productID,
        Price: that.ruleForm_add.Price,
        IsAllowSell: that.ruleForm_add.IsAllowSell,
        IsAllowLargess: that.ruleForm_add.IsAllowLargess,
        EntityID: that.entityIDList,
        Memo: that.$refs["goodsDetail"].getMemo(),
        OriginalText: that.ruleForm_add.OriginalText,
        ImageList: Enumerable.from(that.ruleForm_add.ImageList)
          .select((val) => ({ ImageUrl: val.url }))
          .toArray(),
      };
      API.updateProduct(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.dialogVisible = false;
            that.$message.success("修改成功");
            that.getProductList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
          that.updateLoading = false;
        });
    },
    // 批量设置点击事件
    dropdownClick(val) {
      var that = this;
      that.selectType = val;
      that.setBatchsDialogVisible = true;
    },
    // 批量设置点击确定事件
    submitSetBatch(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.setBatchsDialogVisible = false;
        }
      });
    },
    // 批量设置弹出框取消事件
    resetForm(formName) {
      var that = this;
      this.$refs[formName].resetFields();
      that.setBatchsDialogVisible = false;
    },
    // 获取产品品牌列表
    getProductBrandList: function () {
      let that = this;
      var params = {
        Name: "",
        Active: true,
      };
      productBrandAPI
        .getProductBrandList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productBrandList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**    */
    OriginalText_Change(val) {
      let that = this;
      that.ruleForm_add.OriginalText = val;
    },
    /**    */
    Memo_change(val) {
      let that = this;
      that.ruleForm_add.Memo = val;
    },
    /**    */
    ImageListChange(val) {
      let that = this;
      that.ruleForm_add.ImageList = val;
    },
    /**    */
    getProductDetails(ID) {
      let that = this;
      let param = {
        ID: ID,
      };
      API.getProductDetails(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$set(that.ruleForm_add, "ImageList", []);
            that.$set(that.ruleForm_add, "Memo", "");
            that.$set(that.ruleForm_add, "OriginalText", "");
            res.Data.ImageUrlList.forEach((item) => {
              utils.getCanvasBase64(item.ImageUrl).then((val) => {
                that.ruleForm_add.ImageList.push({ url: val });
              });
            });
            that.ruleForm_add.Memo = res.Data.Memo;
            that.ruleForm_add.OriginalText = res.Data.OriginalText;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
  },
  mounted() {
    var that = this;
    that.isProductEdit = permission.permission(
      that.$route.meta.Permission,
      "iBeauty-Goods-Product-Update"
    );
    that.getProductList();
    that.getProductType();
    that.getEntityList();
    that.getProductBrandList();
  },
  created() {},
};
</script>

<style lang="scss">
.Product {
  .el-input__inner {
    padding: 0 0 0 10px;
  }

  .el-scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
